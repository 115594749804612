define("discourse/plugins/discourse-ai/initializers/ai-bot-replies", ["exports", "discourse/lib/plugin-api", "discourse/widgets/render-glimmer", "discourse-common/lib/deprecated", "discourse/plugins/discourse-ai/discourse/components/ai-bot-header-icon", "discourse/plugins/discourse-ai/discourse/components/post-menu/ai-cancel-streaming-button", "discourse/plugins/discourse-ai/discourse/components/post-menu/ai-debug-button", "discourse/plugins/discourse-ai/discourse/components/post-menu/ai-share-button", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "discourse/plugins/discourse-ai/discourse/lib/ai-streamer/progress-handlers", "@ember/template-factory"], function (_exports, _pluginApi, _renderGlimmer, _deprecated, _aiBotHeaderIcon, _aiCancelStreamingButton, _aiDebugButton, _aiShareButton, _aiBotHelper, _progressHandlers, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let enabledChatBotIds = [];
  let allowDebug = false;
  function isGPTBot(user) {
    return user && enabledChatBotIds.includes(user.id);
  }
  function attachHeaderIcon(api) {
    api.headerIcons.add("ai", _aiBotHeaderIcon.default);
  }
  function initializeAIBotReplies(api) {
    initializePauseButton(api);
    api.modifyClass("controller:topic", {
      pluginId: "discourse-ai",
      onAIBotStreamedReply: function (data) {
        (0, _progressHandlers.streamPostText)(this.model.postStream, data);
      },
      subscribe: function () {
        this._super();
        if (this.model.isPrivateMessage && this.model.details.allowed_users && this.model.details.allowed_users.filter(isGPTBot).length >= 1) {
          // we attempt to recover the last message in the bus
          // so we subscribe at -2
          this.messageBus.subscribe(`discourse-ai/ai-bot/topic/${this.model.id}`, this.onAIBotStreamedReply.bind(this), -2);
        }
      },
      unsubscribe: function () {
        this.messageBus.unsubscribe("discourse-ai/ai-bot/topic/*");
        this._super();
      }
    });
  }
  function initializePersonaDecorator(api) {
    let topicController = null;
    api.decorateWidget(`poster-name:after`, dec => {
      if (!isGPTBot(dec.attrs.user)) {
        return;
      }
      // this is hacky and will need to change
      // trouble is we need to get the model for the topic
      // and it is not available in the decorator
      // long term this will not be a problem once we remove widgets and
      // have a saner structure for our model
      topicController = topicController || api.container.lookup("controller:topic");
      return dec.widget.attach("persona-flair", {
        topicController
      });
    });
    (0, _renderGlimmer.registerWidgetShim)("persona-flair", "span.persona-flair", (0, _templateFactory.createTemplateFactory)(
    /*
      {{@data.topicController.model.ai_persona_name}}
    */
    {
      "id": "9jYzNJdM",
      "block": "[[[1,[30,1,[\"topicController\",\"model\",\"ai_persona_name\"]]]],[\"@data\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/initializers/ai-bot-replies.js",
      "isStrictMode": false
    }));
  }
  function initializePauseButton(api) {
    const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref2 => {
      let {
        value: dag,
        context: {
          post,
          firstButtonKey
        }
      } = _ref2;
      if (isGPTBot(post.user)) {
        dag.add("ai-cancel-gpt", _aiCancelStreamingButton.default, {
          before: firstButtonKey,
          after: ["ai-share", "ai-debug"]
        });
      }
    });
    const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
    (0, _deprecated.withSilencedDeprecations)(silencedKey, () => initializePauseWidgetButton(api));
  }
  function initializePauseWidgetButton(api) {
    api.addPostMenuButton("cancel-gpt", post => {
      if (isGPTBot(post.user)) {
        return {
          icon: "pause",
          action: "cancelStreaming",
          title: "discourse_ai.ai_bot.cancel_streaming",
          className: "btn btn-default cancel-streaming",
          position: "first"
        };
      }
    });
    api.attachWidgetAction("post", "cancelStreaming", function () {
      _aiCancelStreamingButton.default.cancelStreaming(this.model);
    });
  }
  function initializeDebugButton(api) {
    const currentUser = api.getCurrentUser();
    if (!currentUser || !currentUser.ai_enabled_chat_bots || !allowDebug) {
      return;
    }
    const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref3 => {
      let {
        value: dag,
        context: {
          post,
          firstButtonKey
        }
      } = _ref3;
      if (post.topic?.archetype === "private_message") {
        dag.add("ai-debug", _aiDebugButton.default, {
          before: firstButtonKey,
          after: "ai-share"
        });
      }
    });
    const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
    (0, _deprecated.withSilencedDeprecations)(silencedKey, () => initializeDebugWidgetButton(api));
  }
  function initializeDebugWidgetButton(api) {
    const currentUser = api.getCurrentUser();
    let debugAiResponse = async function (_ref4) {
      let {
        post
      } = _ref4;
      const modal = api.container.lookup("service:modal");
      _aiDebugButton.default.debugAiResponse(post, modal);
    };
    api.addPostMenuButton("debugAi", post => {
      if (post.topic?.archetype !== "private_message") {
        return;
      }
      if (!(0, _aiBotHelper.isPostFromAiBot)(post, currentUser)) {
        return;
      }
      return {
        action: debugAiResponse,
        icon: "info",
        className: "post-action-menu__debug-ai",
        title: "discourse_ai.ai_bot.debug_ai",
        position: "first"
      };
    });
  }
  function initializeShareButton(api) {
    const currentUser = api.getCurrentUser();
    if (!currentUser || !currentUser.ai_enabled_chat_bots) {
      return;
    }
    const transformerRegistered = api.registerValueTransformer("post-menu-buttons", _ref5 => {
      let {
        value: dag,
        context: {
          post,
          firstButtonKey
        }
      } = _ref5;
      if (post.topic?.archetype === "private_message") {
        dag.add("ai-share", _aiShareButton.default, {
          before: firstButtonKey
        });
      }
    });
    const silencedKey = transformerRegistered && "discourse.post-menu-widget-overrides";
    (0, _deprecated.withSilencedDeprecations)(silencedKey, () => initializeShareWidgetButton(api));
  }
  function initializeShareWidgetButton(api) {
    const currentUser = api.getCurrentUser();
    let shareAiResponse = async function (_ref6) {
      let {
        post,
        showFeedback
      } = _ref6;
      const modal = api.container.lookup("service:modal");
      _aiShareButton.default.shareAiResponse(post, modal, showFeedback);
    };
    api.addPostMenuButton("share", post => {
      // for backwards compat so we don't break if topic is undefined
      if (post.topic?.archetype !== "private_message") {
        return;
      }
      if (!(0, _aiBotHelper.isPostFromAiBot)(post, currentUser)) {
        return;
      }
      return {
        action: shareAiResponse,
        icon: "far-copy",
        className: "post-action-menu__share-ai",
        title: "discourse_ai.ai_bot.share",
        position: "first"
      };
    });
  }
  function initializeShareTopicButton(api) {
    const modal = api.container.lookup("service:modal");
    const currentUser = api.container.lookup("current-user:main");
    api.registerTopicFooterButton({
      id: "share-ai-conversation",
      icon: "share-alt",
      label: "discourse_ai.ai_bot.share_ai_conversation.name",
      title: "discourse_ai.ai_bot.share_ai_conversation.title",
      action() {
        (0, _aiBotHelper.showShareConversationModal)(modal, this.topic.id);
      },
      classNames: ["share-ai-conversation-button"],
      dependentKeys: ["topic.ai_persona_name"],
      displayed() {
        return currentUser?.can_share_ai_bot_conversations && this.topic.ai_persona_name;
      }
    });
  }
  var _default = _exports.default = {
    name: "discourse-ai-bot-replies",
    initialize(container) {
      const user = container.lookup("service:current-user");
      if (user?.ai_enabled_chat_bots) {
        enabledChatBotIds = user.ai_enabled_chat_bots.map(bot => bot.id);
        allowDebug = user.can_debug_ai_bot_conversations;
        (0, _pluginApi.withPluginApi)("1.6.0", attachHeaderIcon);
        (0, _pluginApi.withPluginApi)("1.34.0", initializeAIBotReplies);
        (0, _pluginApi.withPluginApi)("1.6.0", initializePersonaDecorator);
        (0, _pluginApi.withPluginApi)("1.34.0", api => initializeDebugButton(api, container));
        (0, _pluginApi.withPluginApi)("1.34.0", api => initializeShareButton(api, container));
        (0, _pluginApi.withPluginApi)("1.22.0", api => initializeShareTopicButton(api, container));
      }
    }
  };
});