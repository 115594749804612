define("discourse/plugins/discourse-ai/discourse/components/ai-suggestion-dropdown", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _object, _didInsert, _service, _dButton, _ajax, _ajaxError, _decorators, _I18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AISuggestionDropdown extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "composer", [_service.inject]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "showMenu", [_tracking.tracked], function () {
      return false;
    }))();
    #showMenu = (() => (dt7948.i(this, "showMenu"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "generatedSuggestions", [_tracking.tracked], function () {
      return [];
    }))();
    #generatedSuggestions = (() => (dt7948.i(this, "generatedSuggestions"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "suggestIcon", [_tracking.tracked], function () {
      return "discourse-sparkles";
    }))();
    #suggestIcon = (() => (dt7948.i(this, "suggestIcon"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "showErrors", [_tracking.tracked], function () {
      return false;
    }))();
    #showErrors = (() => (dt7948.i(this, "showErrors"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked], function () {
      return "";
    }))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    SUGGESTION_TYPES = {
      title: "suggest_title",
      category: "suggest_category",
      tag: "suggest_tags"
    };
    willDestroy() {
      super.willDestroy(...arguments);
      document.removeEventListener("click", this.onClickOutside);
    }
    get showAIButton() {
      const minCharacterCount = 40;
      const isShowAIButton = this.composer.model.replyLength > minCharacterCount;
      const composerFields = document.querySelector(".composer-fields");
      if (composerFields) {
        if (isShowAIButton) {
          composerFields.classList.add("showing-ai-suggestions");
        } else {
          composerFields.classList.remove("showing-ai-suggestions");
        }
      }
      return isShowAIButton;
    }
    get disableSuggestionButton() {
      return this.loading;
    }
    applyClasses() {
      if (this.showAIButton) {
        document.querySelector(".composer-fields")?.classList.add("showing-ai-suggestions");
      } else {
        document.querySelector(".composer-fields")?.classList.remove("showing-ai-suggestions");
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "applyClasses", [_object.action]))();
    onClickOutside(event) {
      const menu = document.querySelector(".ai-title-suggestions-menu");
      if (event.target === menu) {
        return;
      }
      return this.#closeMenu();
    }
    static #_11 = (() => dt7948.n(this.prototype, "onClickOutside", [_decorators.bind]))();
    handleClickOutside() {
      document.addEventListener("click", this.onClickOutside);
    }
    static #_12 = (() => dt7948.n(this.prototype, "handleClickOutside", [_object.action]))();
    applySuggestion(suggestion) {
      if (!this.args.mode) {
        return;
      }
      const composer = this.args?.composer;
      if (!composer) {
        return;
      }
      if (this.args.mode === this.SUGGESTION_TYPES.title) {
        composer.set("title", suggestion);
        return this.#closeMenu();
      }
      if (this.args.mode === this.SUGGESTION_TYPES.category) {
        const selectedCategoryId = this.composer.categories.find(c => c.slug === suggestion).id;
        composer.set("categoryId", selectedCategoryId);
        return this.#closeMenu();
      }
      if (this.args.mode === this.SUGGESTION_TYPES.tag) {
        this.#updateTags(suggestion, composer);
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "applySuggestion", [_object.action]))();
    async performSuggestion() {
      if (!this.args.mode) {
        return;
      }
      if (this.composer.model.replyLength === 0) {
        return this.dialog.alert(_I18n.default.t("discourse_ai.ai_helper.missing_content"));
      }
      this.loading = true;
      this.suggestIcon = "spinner";
      return (0, _ajax.ajax)(`/discourse-ai/ai-helper/${this.args.mode}`, {
        method: "POST",
        data: {
          text: this.composer.model.reply
        }
      }).then(data => {
        this.#assignGeneratedSuggestions(data, this.args.mode);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.loading = false;
        this.suggestIcon = "sync-alt";
        this.showMenu = true;
        if (this.args.mode === "suggest_category") {
          document.querySelector(".category-input")?.classList.add("showing-ai-suggestion-menu");
        }
      });
    }
    static #_14 = (() => dt7948.n(this.prototype, "performSuggestion", [_object.action]))();
    #closeMenu() {
      if (this.showMenu && this.args.mode === "suggest_category") {
        document.querySelector(".category-input")?.classList.remove("showing-ai-suggestion-menu");
      }
      this.suggestIcon = "discourse-sparkles";
      this.showMenu = false;
      this.showErrors = false;
      this.errors = "";
    }
    #updateTags(suggestion, composer) {
      const maxTags = this.siteSettings.max_tags_per_topic;
      if (!composer.tags) {
        composer.set("tags", [suggestion]);
        // remove tag from the list of suggestions once added
        this.generatedSuggestions = this.generatedSuggestions.filter(s => s !== suggestion);
        return;
      }
      const tags = composer.tags;
      if (tags?.length >= maxTags) {
        // Show error if trying to add more tags than allowed
        this.showErrors = true;
        this.error = _I18n.default.t("select_kit.max_content_reached", {
          count: maxTags
        });
        return;
      }
      tags.push(suggestion);
      composer.set("tags", [...tags]);
      // remove tag from the list of suggestions once added
      return this.generatedSuggestions = this.generatedSuggestions.filter(s => s !== suggestion);
    }
    #tagSelectorHasValues() {
      return this.args.composer?.tags && this.args.composer?.tags.length > 0;
    }
    #assignGeneratedSuggestions(data, mode) {
      if (mode === this.SUGGESTION_TYPES.title) {
        return this.generatedSuggestions = data.suggestions;
      }
      const suggestions = data.assistant.map(s => s.name);
      if (mode === this.SUGGESTION_TYPES.tag) {
        if (this.#tagSelectorHasValues()) {
          // Filter out tags if they are already selected in the tag input
          return this.generatedSuggestions = suggestions.filter(t => !this.args.composer.tags.includes(t));
        } else {
          return this.generatedSuggestions = suggestions;
        }
      }
      return this.generatedSuggestions = suggestions;
    }
    static #_15 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showAIButton}}
          <DButton
            @icon={{this.suggestIcon}}
            @title="discourse_ai.ai_helper.suggest"
            @action={{this.performSuggestion}}
            @disabled={{this.disableSuggestionButton}}
            class="suggestion-button {{if this.loading 'is-loading'}}"
            ...attributes
          />
        {{/if}}
    
        {{#if this.showMenu}}
          {{! template-lint-disable modifier-name-case }}
          <ul
            class="popup-menu ai-suggestions-menu"
            {{didInsert this.handleClickOutside}}
          >
            {{#if this.showErrors}}
              <li class="ai-suggestions-menu__errors">{{this.error}}</li>
            {{/if}}
            {{#each this.generatedSuggestions as |suggestion index|}}
              <li data-name={{suggestion}} data-value={{index}}>
                <DButton
                  @translatedLabel={{suggestion}}
                  @action={{fn this.applySuggestion suggestion}}
                  class="popup-menu-btn"
                />
              </li>
            {{/each}}
          </ul>
        {{/if}}
      
    */
    {
      "id": "Cn6HectJ",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showAIButton\"]],[[[1,\"      \"],[8,[32,0],[[16,0,[29,[\"suggestion-button \",[52,[30,0,[\"loading\"]],\"is-loading\"]]]],[17,1]],[[\"@icon\",\"@title\",\"@action\",\"@disabled\"],[[30,0,[\"suggestIcon\"]],\"discourse_ai.ai_helper.suggest\",[30,0,[\"performSuggestion\"]],[30,0,[\"disableSuggestionButton\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showMenu\"]],[[[1,\"      \"],[11,\"ul\"],[24,0,\"popup-menu ai-suggestions-menu\"],[4,[32,1],[[30,0,[\"handleClickOutside\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"showErrors\"]],[[[1,\"          \"],[10,\"li\"],[14,0,\"ai-suggestions-menu__errors\"],[12],[1,[30,0,[\"error\"]]],[13],[1,\"\\n\"]],[]],null],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"generatedSuggestions\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[15,\"data-name\",[30,2]],[15,\"data-value\",[30,3]],[12],[1,\"\\n            \"],[8,[32,0],[[24,0,\"popup-menu-btn\"]],[[\"@translatedLabel\",\"@action\"],[[30,2],[28,[32,2],[[30,0,[\"applySuggestion\"]],[30,2]],null]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&attrs\",\"suggestion\",\"index\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-suggestion-dropdown.js",
      "scope": () => [_dButton.default, _didInsert.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AISuggestionDropdown;
});