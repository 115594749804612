define("discourse/plugins/discourse-ai/initializers/related-topics", ["exports", "@glimmer/tracking", "discourse/components/basic-topic-list", "discourse/lib/plugin-api", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _tracking, _basicTopicList, _pluginApi, _dIcon, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RelatedTopics = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div
      role="complementary"
      aria-labelledby="related-topics-title"
      id="related-topics"
      class="more-topics__list"
    >
      <h3 id="related-topics-title" class="more-topics__list-title">
        {{icon "discourse-sparkles"}}{{i18n "discourse_ai.related_topics.title"}}
      </h3>
      <div class="topics">
        <BasicTopicList @topics={{@topic.relatedTopics}} />
      </div>
    </div>
  
  */
  {
    "id": "ub/16OCY",
    "block": "[[[1,\"\\n  \"],[10,0],[14,\"role\",\"complementary\"],[14,\"aria-labelledby\",\"related-topics-title\"],[14,1,\"related-topics\"],[14,0,\"more-topics__list\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,1,\"related-topics-title\"],[14,0,\"more-topics__list-title\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse-sparkles\"],null]],[1,[28,[32,1],[\"discourse_ai.related_topics.title\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"topics\"],[12],[1,\"\\n      \"],[8,[32,2],null,[[\"@topics\"],[[30,1,[\"relatedTopics\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/initializers/related-topics.js",
    "scope": () => [_dIcon.default, _i18n.default, _basicTopicList.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "related-topics:RelatedTopics"));
  var _default = _exports.default = {
    name: "discourse-ai-related-topics",
    initialize(container) {
      const settings = container.lookup("service:site-settings");
      if (!settings.ai_embeddings_enabled || !settings.ai_embeddings_semantic_related_topics_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.37.2", api => {
        api.registerMoreTopicsTab({
          id: "related-topics",
          name: (0, _i18n.default)("discourse_ai.related_topics.pill"),
          icon: "discourse-sparkles",
          component: RelatedTopics,
          condition: _ref => {
            let {
              topic
            } = _ref;
            return topic.relatedTopics?.length;
          }
        });
        api.modifyClass("model:topic", Superclass => class extends Superclass {
          static #_ = (() => dt7948.g(this.prototype, "related_topics", [_tracking.tracked]))();
          #related_topics = (() => (dt7948.i(this, "related_topics"), void 0))();
          get relatedTopics() {
            return this.related_topics?.map(topic => this.store.createRecord("topic", topic));
          }
          static #_2 = (() => dt7948.n(this.prototype, "relatedTopics", [_tracking.cached]))();
        });
        api.modifyClass("model:post-stream", Superclass => class extends Superclass {
          _setSuggestedTopics(result) {
            super._setSuggestedTopics(...arguments);
            this.topic.related_topics = result.related_topics;
          }
        });
      });
    }
  };
});