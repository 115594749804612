define("discourse/plugins/discourse-ai/discourse/components/ai-gist-toggle", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "float-kit/components/d-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _dButton, _dropdownMenu, _dIcon, _i18n, _dMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiGistToggle extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "gistPreference", [_service.service]))();
    #gistPreference = (() => (dt7948.i(this, "gistPreference"), void 0))();
    get shouldShow() {
      return this.router.currentRoute.attributes?.list?.topics?.some(topic => topic.ai_topic_gist);
    }
    get buttons() {
      return [{
        id: "gists_enabled",
        label: "discourse_ai.summarization.gists_enabled_long",
        icon: "discourse-sparkles"
      }, {
        id: "gists_disabled",
        label: "discourse_ai.summarization.gists_disabled",
        icon: "far-eye-slash"
      }];
    }
    onRegisterApi(api) {
      this.dMenu = api;
    }
    static #_3 = (() => dt7948.n(this.prototype, "onRegisterApi", [_object.action]))();
    onSelect(optionId) {
      this.gistPreference.setPreference(optionId);
      this.dMenu.close();
    }
    static #_4 = (() => dt7948.n(this.prototype, "onSelect", [_object.action]))();
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldShow}}
    
          <DMenu
            @modalForMobile={{true}}
            @autofocus={{true}}
            @identifier="ai-gists-dropdown"
            @onRegisterApi={{this.onRegisterApi}}
            @triggerClass="btn-transparent"
          >
            <:trigger>
              <span class="d-button-label">
                {{i18n
                  (concat
                    "discourse_ai.summarization." this.gistPreference.preference
                  )
                }}
              </span>
              {{icon "angle-down"}}
            </:trigger>
            <:content>
              <DropdownMenu as |dropdown|>
                {{#each this.buttons as |button|}}
                  <dropdown.item>
                    <DButton
                      @label={{button.label}}
                      @icon={{button.icon}}
                      class="btn-transparent"
                      @action={{fn this.onSelect button.id}}
                    />
                  </dropdown.item>
                {{/each}}
              </DropdownMenu>
            </:content>
          </DMenu>
        {{/if}}
      
    */
    {
      "id": "jxelvwjf",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShow\"]],[[[1,\"\\n      \"],[8,[32,0],null,[[\"@modalForMobile\",\"@autofocus\",\"@identifier\",\"@onRegisterApi\",\"@triggerClass\"],[true,true,\"ai-gists-dropdown\",[30,0,[\"onRegisterApi\"]],\"btn-transparent\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n          \"],[10,1],[14,0,\"d-button-label\"],[12],[1,\"\\n            \"],[1,[28,[32,1],[[28,[32,2],[\"discourse_ai.summarization.\",[30,0,[\"gistPreference\",\"preference\"]]],null]],null]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[1,[28,[32,3],[\"angle-down\"],null]],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[32,4],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"buttons\"]]],null]],null],null,[[[1,\"              \"],[8,[30,1,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n                \"],[8,[32,5],[[24,0,\"btn-transparent\"]],[[\"@label\",\"@icon\",\"@action\"],[[30,2,[\"label\"]],[30,2,[\"icon\"]],[28,[32,6],[[30,0,[\"onSelect\"]],[30,2,[\"id\"]]],null]]],null],[1,\"\\n              \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"          \"]],[1]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"dropdown\",\"button\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-gist-toggle.js",
      "scope": () => [_dMenu.default, _i18n.default, _helper.concat, _dIcon.default, _dropdownMenu.default, _dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiGistToggle;
});