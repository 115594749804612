define("discourse/plugins/discourse-ai/discourse/services/gist-preference", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class GistPreference extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "preference", [_tracking.tracked], function () {
      return localStorage.getItem("aiGistPreference") || "gists_disabled";
    }))();
    #preference = (() => (dt7948.i(this, "preference"), void 0))();
    setPreference(value) {
      this.preference = value;
      localStorage.setItem("aiGistPreference", value);
    }
  }
  _exports.default = GistPreference;
});