define("discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/composer", "I18n", "discourse/plugins/discourse-ai/discourse/components/modal/share-full-topic-modal"], function (_exports, _ajax, _ajaxError, _composer, _I18n, _shareFullTopicModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.composeAiBotMessage = composeAiBotMessage;
  _exports.isPostFromAiBot = isPostFromAiBot;
  _exports.showShareConversationModal = showShareConversationModal;
  const MAX_PERSONA_USER_ID = -1200;
  function isPostFromAiBot(post, currentUser) {
    return post.user_id <= MAX_PERSONA_USER_ID || !!currentUser?.ai_enabled_chat_bots?.any(bot => post.username === bot.username);
  }
  function showShareConversationModal(modal, topicId) {
    (0, _ajax.ajax)(`/discourse-ai/ai-bot/shared-ai-conversations/preview/${topicId}.json`).then(payload => {
      modal.show(_shareFullTopicModal.default, {
        model: payload
      });
    }).catch(_ajaxError.popupAjaxError);
  }
  function composeAiBotMessage(targetBot, composer) {
    const currentUser = composer.currentUser;
    let botUsername = currentUser.ai_enabled_chat_bots.find(bot => bot.model_name === targetBot).username;
    composer.focusComposer({
      fallbackToNewTopic: true,
      openOpts: {
        action: _composer.default.PRIVATE_MESSAGE,
        recipients: botUsername,
        topicTitle: _I18n.default.t("discourse_ai.ai_bot.default_pm_prefix"),
        archetypeId: "private_message",
        draftKey: "private_message_ai",
        hasGroups: false,
        warningsDisabled: true,
        skipDraftCheck: true
      }
    });
  }
});