define("discourse/plugins/discourse-ai/discourse/connectors/after-composer-category-input/ai-category-suggestion", ["exports", "@glimmer/component", "@ember/service", "discourse/plugins/discourse-ai/discourse/components/ai-suggestion-dropdown", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _aiSuggestionDropdown, _showAiHelper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiCategorySuggestion extends _component.default {
    static shouldRender(outletArgs, helper) {
      return (0, _showAiHelper.showComposerAiHelper)(outletArgs?.composer, helper.siteSettings, helper.currentUser, "suggestions");
    }
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.siteSettings.ai_embeddings_enabled}}
          <AISuggestionDropdown
            @mode="suggest_category"
            @composer={{@outletArgs.composer}}
            class="suggest-category-button"
          />
        {{/if}}
      
    */
    {
      "id": "tgy79Whv",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"ai_embeddings_enabled\"]],[[[1,\"      \"],[8,[32,0],[[24,0,\"suggest-category-button\"]],[[\"@mode\",\"@composer\"],[\"suggest_category\",[30,1,[\"composer\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/after-composer-category-input/ai-category-suggestion.js",
      "scope": () => [_aiSuggestionDropdown.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiCategorySuggestion;
});